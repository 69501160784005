import { Controller } from 'stimulus';
import algoliasearch from 'algoliasearch/lite';
import autocomplete from 'autocomplete.js';
import { isNil } from 'lodash';

import dealTemplate from './templates/dealTemplate';
import userTemplate from './templates/userTemplate';
import empty from './templates/empty';

export default class QuickSearch extends Controller {
  static targets = ['input', 'dropdownMenuContainer'];

  client = undefined;
  autocomplete = undefined;
  hitsPerPage = 8;
  isPersistent = false;
  attribute = 'id';

  initialize() {
    this.client = algoliasearch(
      'FY4P0IT4QN',
      '338c39b825f5652ddedf87ef8f22a4dc',
    );
  }

  connect() {
    const { attribute, hitsPerPage, persistent } = this.element.dataset;

    this.hitsPerPage = isNil(hitsPerPage) ? this.hitsPerPage : hitsPerPage;
    this.isPersistent = isNil(persistent) ? this.isPersistent : persistent;
    this.attribute = isNil(attribute) ? this.attribute : attribute;

    const options = {
      dropdownMenuContainer: this.dropdownMenuContainerTarget,
      hint: false,
      keyboardShortcuts: ['s'],
      openOnFocus: true,
      tabAutocomplete: false,
      cssClasses: {
        root: 'quick-search__wrapper',
        dropdownMenu: 'quick-search__dropdown-menu',
        noPrefix: true,
      },
    };

    if (this.inputTarget.readOnly || this.inputTarget.disabled) return;

    this.autocomplete = autocomplete(this.inputTarget, options, this.datasets);

    this.autocomplete.on(
      'autocomplete:selected',
      this.handleAutocompleteSelected,
    );

    // allow navbar quick search as scrollable
    const parent = document.querySelector('.quick-search__dropdown-menu');
    const children = parent.querySelectorAll('div');
    const scrollableWrapper = document.createElement('div');
    scrollableWrapper.classList.add('quick-search__content');
    parent.appendChild(scrollableWrapper);
    children.forEach(child => {
      scrollableWrapper.appendChild(child);
    });
  }

  disconnect() {
    if (isNil(this.autocomplete)) {
      return;
    }

    this.autocomplete.off(
      'autocomplete:selected',
      this.handleAutocompleteSelected,
    );

    this.autocomplete.autocomplete.destroy();
  }

  get datasets() {
    const { sources } = this.element.dataset;

    const datasets = [];

    const hostname = window.location.hostname;
    const prefix = hostname.includes('monolith.bc.upright.us')
      ? 'BuildersCapital'
      : '';
    if (sources.indexOf('deals') > -1) {
      const dealsIndex = this.client.initIndex(prefix + 'Deal_production');

      datasets.push({
        source: this.search(dealsIndex),
        name: 'deals',
        templates: {
          header: '<h4 class="quick-search__title">Deals</h4>',
          suggestion: (hit, answer) =>
            dealTemplate(hit, answer, this.isPersistent),
          empty,
        },
      });
    }

    if (sources.indexOf('users') > -1) {
      const usersIndex = this.client.initIndex(prefix + 'User_production');

      datasets.push({
        source: this.search(usersIndex),
        name: 'users',
        templates: {
          header: '<h4 class="quick-search__title">Users</h4>',
          suggestion: (hit, answer) =>
            userTemplate(hit, answer, this.isPersistent),
          empty,
        },
      });
    }
    return datasets;
  }

  search = index => (query, cb) => {
    const formattedQuery = query.replace(/\\|\//g, '');

    this.element.value = formattedQuery;

    index
      .search(formattedQuery, { hitsPerPage: this.hitsPerPage })
      .then(res => res.hits.filter(hit => hit.status_code !== 'archived'))
      .then(res => cb(res, res))
      .catch(() => cb([]));
  };

  handleAutocompleteSelected = (e, suggestion) => {
    this.autocomplete.autocomplete.setVal(suggestion[this.attribute]);
    this.inputTarget.blur();
    this.inputTarget.dispatchEvent(
      new CustomEvent('quicksearch:selected', {
        detail: { suggestion },
        bubbles: true,
      }),
    );
  };
}
